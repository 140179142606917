@import '../MainPage/Languages.scss';

// @font-face {
//   font-family: "cinzel";
//   src: local('cinzel'),
//     url("../assets/fonts/cinzel/Cinzel-Regular.otf") format("truetype");
// }

// @font-face {
//   font-family: "cinzel-bold";
//   src: local('cinzel-bold'),
//     url("../assets/fonts/cinzel/Cinzel-Bold.otf") format("truetype");
// }

@font-face {
  font-family: "JetBrains Mono";
  src: local('JetBrains Mono'),
    url("../assets/fonts/JetBrainsMono/fonts/ttf/JetBrainsMono-Light.ttf") format("truetype");
}


body {
  background-color: rgb(63, 86, 109);
  margin: 0 2em;
  color: #F5FBEF;
  font-family: 'JetBrains Mono';
}

a:hover {
  font-weight: bold;
}

.social-icons {
  a {
    margin: 0 0.5em;
  }

  .socials:hover {
    color: #50A2A7;
  }

}

footer {
  // font-family: 'Times New Roman', Times, serif;
  text-align: center;
}

footer a {
  color: #fff
}

.button-like {
  &:hover {
    cursor: pointer;
    color: #50A2A7;
  }
}

.languages {
  font-size: 1.2em;
}

.id-card {
  .languages {
    font-size: 1em;
    margin-top: 0;
  }
}

.languages span:hover {
  font-weight: bold;
}

.social-icons,
.languages {
  margin-top: 50px;
}

.normal-content {
  font-family: 'Courier New', Courier, monospace;
}

.links-container {
  line-height: 2em;
}


@media screen and (min-width: 600px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-content {
      margin-left: 2em;
    }
  }

  .links-container {
    display: flex;
    gap: 2em;
    flex-wrap: wrap;
  }

}